import React from "react";
import Layout from "../components/reusable/Layout";
import { graphql } from "gatsby";
import Seo from "../components/reusable/Seo";
import {
  HeroSection,
  SoftwareSection,
  SuggestionSection,
} from "../styles/pages/ats";
import lap from "../images/ats/lap.png";
import softwareImage from "../images/ats/software-image.png";
import ats from "../images/ats/ats.png";
import documents from "../images/ats/documents.png";
import users from "../images/ats/users.png";
import search from "../images/ats/search.png";
import mail from "../images/ats/mail.png";
import desktop from "../images/ats/desktop.png";
import windows from "../images/ats/windows.png";
import pieces from "../images/ats/pieces.png";
import chartPie from "../images/ats/chart-pie.png";
import { CompaniesSection } from "../styles/pages/home";
import Carousel from "../components/reusable/Carousel";
import { useTranslation } from "react-i18next";
import { Bg, Text } from "../components/ui";
import { Button, Stack, Grid } from "@mui/material";

const dermavanBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FDermavan%20Negro.png?alt=media&token=824f3023-ea05-41aa-adb8-9979dddf73d9";
const flatBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FFlat%20Negro.png?alt=media&token=3ac0b29b-c34e-422f-8349-8e38eff2f640";
const leadgeniosBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FlEADGENIOS%20Negro.png?alt=media&token=92aba62d-ab6c-404f-8c0a-27fca2129dfb";
const echaleBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FLOGO-E%CC%81chale-09%20Negro.png?alt=media&token=0e146370-0bab-4086-b4c7-4e2719cfaedd";
const walliaBlack =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2FWallia%20Negro.png?alt=media&token=4d6b80e1-db56-4af0-ac3a-f9868de38f9b";

const Ats = () => {
  const { t } = useTranslation("ats");

  const items = React.useMemo(
    () => [
      {
        title: t("infoItemTitle1"),
        description: t("infoItemDescription1"),
        icon: documents,
        id: 1,
      },
      {
        title: t("infoItemTitle2"),
        description: t("infoItemDescription2"),
        icon: users,
        id: 2,
      },
      {
        title: t("infoItemTitle3"),
        description: t("infoItemDescription3"),
        icon: search,
        id: 3,
      },
      {
        title: t("infoItemTitle4"),
        description: t("infoItemDescription4"),
        icon: mail,
        id: 4,
      },
      {
        title: t("infoItemTitle5"),
        description: t("infoItemDescription5"),
        icon: desktop,
        id: 5,
      },
      {
        title: t("infoItemTitle6"),
        description: t("infoItemDescription6"),
        icon: windows,
        id: 6,
      },
      {
        title: t("infoItemTitle7"),
        description: t("infoItemDescription7"),
        icon: pieces,
        id: 7,
      },
      {
        title: t("infoItemTitle8"),
        description: t("infoItemDescription8"),
        icon: chartPie,
        id: 8,
      },
      {
        title: t("infoItemTitle9"),
        description: t("infoItemDescription9"),
        icon: desktop,
        id: 9,
      },
    ],
    [t]
  );

  const quickActions = React.useMemo(
    () => [
      {
        icon: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2Fstars.png?alt=media&token=2d3a97d9-4340-4446-a8d1-bc6448a41a9d",
        title: t("suggestionsLisTitle1"),
        description: t("suggestionsLisDescription1"),
        id: 1,
      },
      {
        icon: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2Fmessage.png?alt=media&token=3af31b9b-54c6-4238-80c7-0e88b83b6d21",
        title: t("suggestionsLisTitle2"),
        description: t("suggestionsLisDescription2"),
        id: 2,
      },
      {
        icon: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2Fpencil.png?alt=media&token=06bcdb5f-b28b-4589-b558-4389a6a851d5",
        title: t("suggestionsLisTitle3"),
        description: t("suggestionsLisDescription3"),
        id: 3,
      },
    ],
    [t]
  );

  const usersLogos = React.useMemo(
    () => [
      {
        logo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2FlogoML.png?alt=media&token=a48f506e-8f78-4d46-8392-7da22961670e",
        name: "Mercado Libre",
        id: 1,
      },
      {
        logo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2FJustoLogo.png?alt=media&token=296e4115-54a8-487f-bd59-0d79c0daf515",
        name: "Justo",
        id: 2,
      },
      {
        logo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2FFemsaLogo.png?alt=media&token=212a55ea-fbb8-431c-b790-29c3c3bc4928",
        name: "FEMSA",
        id: 3,
      },
      {
        logo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2FAIRTMLogo.png?alt=media&token=01a3668f-ee57-43b0-a874-d60b824a633c",
        name: "AIRTM",
        id: 4,
      },
      {
        logo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2FCrediJustoLogo.png?alt=media&token=4f4ebb5f-90e7-455f-9481-517f8f9b4562",
        name: "Credijusto",
        id: 5,
      },
    ],
    []
  );

  return (
    <>
      <Seo
        title="ATS - CRM para gestión de vacantes"
        canonical="https://empresas.lapieza.io/ats"
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Layout>
        <HeroSection>
          <Stack spacing={2} alignItems="center">
            <Text.SubTitle
              text={t("heroSubTitle")}
              color="primary.orange"
              fontSize="2rem"
            />
            <Text.Title
              text={t("heroTitle")}
              fontSize="3rem"
              isH1
              color="#003D53"
              sx={{
                maxWidth: "540px",
                textAlign: "center",
              }}
            />
            <p>{t("heroDesc")}</p>
            <Button
              href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                width: "280px",
              }}
            >
              {t("requestInfo")}
            </Button>
            <figure>
              <img
                className="main-image-hero"
                src={lap}
                alt="Platform LaPieza"
              />
            </figure>
          </Stack>
        </HeroSection>
        <CompaniesSection>
          <div className="companies-section">
            <Carousel
              logos={[
                dermavanBlack,
                flatBlack,
                leadgeniosBlack,
                echaleBlack,
                walliaBlack,
              ]}
            />
          </div>
        </CompaniesSection>
        <SoftwareSection>
          <Stack alignItems="center" mb={5}>
            <Text.SubTitle
              color="primary.orange"
              fontSize="2.3rem"
              text={t("softwareTitle")}
            />
          </Stack>
          <div className="main-landing-container-row">
            <figure>
              <img src={softwareImage} alt="Platform LaPieza" />
            </figure>
            <article>
              <Stack spacing={2}>
                <Text.Title
                  fontSize="3.4rem"
                  textAlign="start"
                  text={t("softwareItemTitle")}
                />
                <Text.Body textAlign="start" fontSize="1.6rem">
                  {t("softwareItemDescription")}
                </Text.Body>
                <Text.Body textAlign="start" fontSize="2.1rem">
                  {t("softwareItemText")}
                </Text.Body>
                <Button
                  href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="contained"
                  sx={{
                    width: "280px",
                  }}
                >
                  {t("requestInfo")}
                </Button>
              </Stack>
            </article>
          </div>
        </SoftwareSection>
        <Stack
          height={{
            md: "450px",
            sm: "auto",
          }}
        >
          <Bg.Gradient>
            <Grid container height="100%" spacing={2}>
              <Grid item lg={5} md={6} sm={12} height="100%">
                <Stack
                  spacing={2}
                  alignItems="start"
                  height="100%"
                  justifyContent="center"
                  padding={{
                    md: "60px 20px 60px 120px",
                    xs: "40px",
                    sm: "40px",
                  }}
                >
                  <Text.Title fontSize="3.6rem" textAlign="start">
                    {t("careerItemTitle")}
                  </Text.Title>
                  <Text.Body textAlign="start" fontSize="1.6rem">
                    {t("careerItemDescription")}
                  </Text.Body>
                </Stack>
              </Grid>
              <Grid item lg={7} md={6} sm={12} height="100%">
                <Stack
                  height="100%"
                  width="100%"
                  padding={{
                    xs: "40px",
                    sm: "40px",
                  }}
                >
                  <Stack height="100%" justifyContent="end">
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2Fats-careers.png?alt=media&token=faba1c38-0a0a-4951-81e8-0012cc7b506d"
                      alt="Platform LaPieza"
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Bg.Gradient>
        </Stack>
        <SuggestionSection>
          <div className="suggestion-section main-landing-container-row">
            <figure>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2FEle-ats.png?alt=media&token=65f75c1f-a6ae-4f9b-8131-cd75d7d73bff"
                alt="Hunting LaPieza"
              />
            </figure>
            <Stack maxWidth="450px" alignItems="start" spacing={2}>
              <Text.SubTitle
                color="primary.orange"
                fontSize="1.8rem"
                textAlign="start"
              >
                {t("suggestionsItemSubtitle1")}
              </Text.SubTitle>
              <Text.Title fontSize="3rem" textAlign="start">
                {t("suggestionsItemTitle1")}
              </Text.Title>
              <Text.Body fontSize="1.6rem" textAlign="start">
                {t("suggestionsItemDescription1")}
              </Text.Body>
            </Stack>
          </div>
          <div className="candidates-section main-landing-container-row">
            <Stack maxWidth="450px" alignItems="start" spacing={2}>
              <Text.SubTitle
                color="primary.orange"
                fontSize="1.8rem"
                textAlign="start"
              >
                {t("suggestionsItemSubtitle2")}
              </Text.SubTitle>
              <Text.Title fontSize="3rem" textAlign="start">
                {t("suggestionsItemTitle2")}
              </Text.Title>
              <Text.Body fontSize="1.6rem" textAlign="start">
                {t("suggestionsItemDescription2")}
              </Text.Body>
              <Button
                href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
              >
                {t("requestInfo")}
              </Button>
            </Stack>
            <figure>
              <img src={ats} alt="ATS LaPieza" />
            </figure>
          </div>
          <div className="info-section main-landing-container-row">
            {quickActions.map((action) => (
              <article key={action.id}>
                <img src={action.icon} alt="Magic Actions LaPieza" />
                <Stack alignItems="start" spacing={2}>
                  <Text.Title textAligin="start" fontSize="1.8rem">
                    {action.title}
                  </Text.Title>
                  <Text.Body textAlign="start" fontSize="1.6rem">
                    {action.description}
                  </Text.Body>
                </Stack>
              </article>
            ))}
          </div>
        </SuggestionSection>
        <Bg.Linear>
          <Stack
            padding={{
              md: "80px 40px 20px 60px",
              xs: "40px",
              sm: "40px",
            }}
          >
            <Text.Title fontSize="3rem" textAligin="center">
              {t("InfoTitle")}
            </Text.Title>
            <Grid container>
              {items.map((item) => (
                <Grid item lg={4} md={6} sm={12} key={item.id}>
                  <Stack
                    alignItems="center"
                    width="100%"
                    padding={7}
                    spacing={3}
                  >
                    <Stack height="40px" width="40px">
                      <img src={item.icon} alt="iconono LaPieza" />
                    </Stack>
                    <Stack width="100%" alignItems="start">
                      <Text.Title textAligin="start" fontSize="1.8rem">
                        {item.title}
                      </Text.Title>
                      <Text.Body textAlign="start" fontSize="1.5rem">
                        {item.description}
                      </Text.Body>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Bg.Linear>
        <Grid container mt={7}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            padding={{
              md: "80px 110px",
              xs: "40px",
              sm: "40px",
            }}
          >
            <Stack spacing={3} alignItems="start">
              <Text.SubTitle
                textAlign="start"
                color="primary.orange"
                fontSize="1.6rem"
              >
                {t("platformSubtitle")}
              </Text.SubTitle>
              <Text.Title textAlign="start" fontSize="3.8rem">
                {t("platformTitle")}
              </Text.Title>
              <Text.Body fontSize="1.8rem" textAlign="start">
                {t("platformDescription")}
              </Text.Body>
              <Button
                href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("requestInfo")}
              </Button>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} sm={12} position="relative" width="100%">
            <Stack width="600px" height="100%" position="absolute" right="-20%">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fats%2FbagsActive.png?alt=media&token=3b8d1d89-238e-45f0-a398-e0837c29f093"
                alt="Integraciones LaPieza"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "right",
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Stack mt={5}>
          <Bg.Gradient>
            <Stack padding={7} alignItems="center" justifyContent="center">
              <Text.Title
                fontSize="3rem"
                sx={{
                  mb: 5,
                }}
              >
                {t("contactTitle")}
              </Text.Title>
              <Button
                href="https://api.whatsapp.com/send/?phone=527771369552&text=%C2%A1Hola%20Alexa!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "320px",
                  fontSize: "1.6rem",
                  mb: 5,
                }}
              >
                {t("heroButton")}
              </Button>
              <Text.Body fontSize="1.8rem">{t("contactDescription")}</Text.Body>
              <Stack
                direction={{
                  md: "row",
                  sm: "column",
                }}
                spacing={4}
                mt={5}
              >
                {usersLogos?.map((user) => (
                  <Stack key={user?.id} width="70px" height="50px">
                    <img
                      src={user.logo}
                      alt={user.name}
                      key={user.id}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Bg.Gradient>
        </Stack>
      </Layout>
    </>
  );
};

export default Ats;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
