import styled from "styled-components";
import { fonts, landingPalette } from "../styles";

export const HeroSection = styled.section`
  padding: 3.8rem 80px 9rem 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 3.8rem 32px 9rem 32px;
  }

  @media (max-width: 425px) {
    padding: 3.8rem 16px 9rem 16px;
  }

  h3 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProBold};
    text-align: center;
    max-width: 62rem;
    margin-bottom: 3.2rem;
  }

  p {
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: ${landingPalette.blue};
    font-family: ${fonts.SofiaProMedium};
    text-align: center;
    max-width: 56rem;
    margin-bottom: 5rem;
  }

  figure {
    max-width: 1038px;
    position: relative;

    .main-image-hero {
      width: 100%;
      object-fit: scale-down;
    }

    .hero-figure {
      position: absolute;
    }

    .hero-figure-one {
      left: 9%;
      top: -6rem;

      @media (max-width: 920px) {
        left: 4%;
        top: -6rem;
        width: 24px;
      }

      @media (max-width: 480px) {
        left: -16px;
      }

      @media (max-width: 375px) {
        top: 0;
      }
    }

    .hero-figure-two {
      right: 9%;
      top: -6rem;

      @media (max-width: 920px) {
        right: 4%;
        top: -6rem;
        width: 24px;
      }

      @media (max-width: 480px) {
        right: -16px;
      }

      @media (max-width: 375px) {
        top: 0;
      }
    }

    .hero-figure-three {
      left: 0;
      top: 2rem;

      @media (max-width: 920px) {
        width: 16px;
      }

      @media (max-width: 480px) {
        left: -16px;
      }

      @media (max-width: 375px) {
        top: 10rem;
      }
    }

    .hero-figure-four {
      right: 0;
      top: 2rem;

      @media (max-width: 920px) {
        width: 16px;
      }

      @media (max-width: 480px) {
        right: -16px;
      }

      @media (max-width: 375px) {
        top: 10rem;
      }
    }
  }
`;

export const SoftwareSection = styled.section`
  padding: 0 80px 9rem 80px;

  @media (max-width: 600px) {
    padding: 0 32px 9rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 9rem 16px;
  }

  h2 {
    font-size: 2.3rem;
    line-height: 2.9rem;
    color: ${landingPalette.orange};
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 8rem;
    text-align: center;
  }

  .main-landing-container-row {
    display: flex;
    justify-content: space-between;
    grid-gap: 5rem;

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  figure {
    max-width: 53rem;
    width: 100%;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  article {
    max-width: 45rem;
    width: 100%;

    h3 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 2rem;
    }

    .description-article {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 2rem;
    }

    .message-article {
      font-size: 2.4rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProMedium};
      margin-bottom: 2rem;
    }
  }
`;

export const SuggestionSection = styled.section`
  padding: 10rem 80px 10rem 80px;

  @media (max-width: 600px) {
    padding: 10rem 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 10rem 16px 10rem 16px;
  }

  .suggestion-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10rem;
    grid-gap: 3.2rem;

    @media (max-width: 920px) {
      grid-gap: 5rem;
      flex-direction: column-reverse;
    }

    figure {
      max-width: 44rem;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    article {
      max-width: 41.3rem;
      width: 100%;

      h2 {
        font-size: 1.6rem;
        line-height: 2.9rem;
        color: ${landingPalette.orange};
        font-family: ${fonts.SofiaProMedium};
        margin-bottom: 2rem;
      }

      h3 {
        font-size: 3.8rem;
        line-height: 4.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProMedium};
      }
    }
  }

  .candidates-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8rem;

    @media (max-width: 920px) {
      grid-gap: 5rem;
      flex-direction: column;
    }

    figure {
      max-width: 46rem;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }

    article {
      max-width: 44rem;
      width: 100%;

      h2 {
        font-size: 1.6rem;
        line-height: 2.9rem;
        color: ${landingPalette.orange};
        font-family: ${fonts.SofiaProMedium};
        margin-bottom: 2rem;
      }

      h3 {
        font-size: 3.8rem;
        line-height: 4.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.8rem;
        line-height: 2.8rem;
        color: ${landingPalette.blue};
        font-family: ${fonts.SofiaProMedium};
      }
    }
  }

  .info-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 5rem;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    article {
      max-width: 36rem;
      width: 100%;

      img {
        width: 45px;
        height: 45px;
        object-fit: cover;
        border-radius: 4px;
        margin-bottom: 2rem;
      }

      h5 {
        font-size: 1.8rem;
        line-height: 3.2rem;
        color: #003245;
        font-family: ${fonts.SofiaProSemiBold};
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.8rem;
        color: #232323;
        font-family: ${fonts.sofiaProRegular};
      }
    }
  }
`;
